// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-v-1-a-js": () => import("./../../../src/pages/v1-a.js" /* webpackChunkName: "component---src-pages-v-1-a-js" */),
  "component---src-pages-v-1-b-js": () => import("./../../../src/pages/v1-b.js" /* webpackChunkName: "component---src-pages-v-1-b-js" */),
  "component---src-pages-v-1-c-js": () => import("./../../../src/pages/v1-c.js" /* webpackChunkName: "component---src-pages-v-1-c-js" */),
  "component---src-pages-v-1-js": () => import("./../../../src/pages/v1.js" /* webpackChunkName: "component---src-pages-v-1-js" */),
  "component---src-pages-v-2-a-js": () => import("./../../../src/pages/v2-a.js" /* webpackChunkName: "component---src-pages-v-2-a-js" */),
  "component---src-pages-v-2-js": () => import("./../../../src/pages/v2.js" /* webpackChunkName: "component---src-pages-v-2-js" */)
}

